import React, { useState } from "react";
import { Flex, Form, FormInstance, Input, InputNumber, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { BlackButton } from "../../../../../common/components/UI";
import { general } from "../../../../../common/lib/schemas/rules";
import { getTemplateScheme } from "../model/scheme";
import { useIntl } from "react-intl";
import { TemplateForm } from "../../../../types/templates";

type AddTemplateProps = {
  form: FormInstance;
  open: boolean;
  onClose: () => unknown;
  onSubmit: (data: TemplateForm) => unknown;
}

const AddTemplateModal: React.FC<AddTemplateProps> = ({ form, open, onClose, onSubmit }) => {
  const { formatMessage: t } = useIntl();
  const rules = getTemplateScheme(t);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string[] }>({
    beneficiary: [],
    iban: [],
    amount: [],
    description: [],
  });

  const onFieldsChange = () => {
    setValidationErrors(prevState => ({
      ...prevState,
      beneficiary: form.getFieldError('recipientName'),
      iban: form.getFieldError('iban'),
      amount: form.getFieldError('amount'),
      description: form.getFieldError('description'),
    }))
  }

  return (
    <Form form={form} validateTrigger='onChange' onFinish={onSubmit} onFieldsChange={onFieldsChange}>
      <Modal
        title={t({ id: 'sections.templates.buttons.add' })}
        open={open}
        closeIcon={<CloseOutlined id='btn__add-templates-close' />}
        centered
        onCancel={onClose}
        footer={
          <Flex justify='center'>
            <BlackButton type='primary' id='btn__add-template-submit'
                         onClick={form.submit}>{t({ id: 'buttons.submit' })}</BlackButton>
          </Flex>
        }
      >
        <Flex vertical justify='center' style={{ paddingTop: 30 }}>
          <Form.Item rules={[general(t)['required-field']]} name='title'>
            <Input placeholder={t({ id: 'inputs.title' })} />
          </Form.Item>
          <Form.Item
            help={<div id="error__beneficiary">{validationErrors.beneficiary[0]}</div>}
            rules={rules.beneficiary}
            name='recipientName'
          >
            <Input placeholder={t({ id: 'screens.payment.create.beneficiary.inputs.beneficiary' })} />
          </Form.Item>
          <Form.Item
            help={<div id="error__iban">{validationErrors.iban[0]}</div>}
            rules={rules.iban} name='iban'
          >
            <Input placeholder={t({ id: 'screens.payment.create.beneficiary.inputs.iban' })} />
          </Form.Item>
          <Form.Item
            help={<div id="error__amount">{validationErrors.amount[0]}</div>}
            rules={rules.amount}
            name='amount'
          >
            <InputNumber placeholder={t({ id: 'screens.payment.create.beneficiary.inputs.amount' })} />
          </Form.Item>
          <Form.Item
            help={<div id="error__description">{validationErrors.description[0]}</div>}
            rules={rules.description}
            name='description'
          >
            <Input placeholder={t({ id: 'screens.payment.create.beneficiary.inputs.details' })} />
          </Form.Item>
        </Flex>
      </Modal>
    </Form>
  )
}

export default AddTemplateModal;
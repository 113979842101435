import { Dropdown, Flex, MenuProps, Typography } from 'antd';
import React, { useState } from 'react';
import SelectCard from './SelectCard';
import './AccountDropdown.scss';
import { Account } from '../../types';

const { Text } = Typography;

type AccountDropdownProps = {
  label: string;
  options: MenuProps['items'];
  currentOption: Account;
  className?: string;
  id?: string;
}

const AccountDropdown: React.FC<AccountDropdownProps> = ({ label, options, currentOption , className, id}) => {
  return (
    <Flex vertical gap={8}>
      <Text>
        {label}
      </Text>
      <Dropdown
        menu={{
          items: options,
          selectable: true,
          defaultSelectedKeys: [String(currentOption.id)],
        }}
        overlayStyle={{ maxHeight: 250, overflow: 'scroll', overflowX: 'hidden' }}
        placement="bottom"
        trigger={['click']}
        overlayClassName="account-dropdown"
      >
        {SelectCard({ currentOption, className, id })}
      </Dropdown>
    </Flex>
  );
};

export default AccountDropdown;
import { general, generalFields } from "../../../../../common/lib/schemas/rules";

export const getSendSepaScheme = (
  t: (descriptor: object, values?: object) => string,
) => ({
  beneficiary: [
    general(t)['required-field'],
    ...generalFields(t)['beneficiary']
  ],
  iban: [
    general(t)['required-field'],
    ...generalFields(t)['iban']
  ],
  bic: [
    general(t)['required-field'],
    ...generalFields(t)['bic']
  ],
  amount: [
    general(t)['required-field'],
    ...generalFields(t)['amount']
  ],
  confirmAmount: [
    general(t)['required-field'],
  ],
  description: [
    general(t)['required-field'],
  ],
});
import React from 'react';
import { Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import RoundContainer from 'common/components/UI/RoundContainer/RoundContainer';
import { getGreyColor } from 'common/lib/utils/helpers';
import { UniversalCard } from 'common/types/cards';
import { Card as CardComponent, GreyContainer } from 'common/components/UI';
import { useIntl } from 'react-intl';

type CardsBlockProps = {
  cards: UniversalCard[];
  color: string;
  isAddCardAvailable: boolean;
  onAddCard?: () => unknown;
  onCard?: (id: number | string) => unknown;
  accountSoftBlocked?: boolean
};

const CardsBlock: React.FC<CardsBlockProps> = ({
  cards = [],
  color,
  isAddCardAvailable,
  onCard,
  onAddCard,
  accountSoftBlocked
}) => {
  const { formatMessage: t } = useIntl();

  return (
    <GreyContainer title={t({ id: 'sections.card.card.title' })}>
      <Space size={8}>
        {cards.map((card) => (
          <CardComponent
            id='river-card-img'
            key={card.id}
            size="m"
            bgColor={color}
            status={card.universalCardStatus}
            shortNumber={card.number?.slice(-4) || ''}
            onClick={() => {
              if (onCard && card.id) onCard(String(card.id));
            }}
          />
        ))}

        {(isAddCardAvailable && !accountSoftBlocked) ? (
          <RoundContainer
            bgColor={getGreyColor(0.08)}
            padding={10}
            pointer
            onClick={onAddCard}>
            <PlusOutlined style={{ fontSize: 18 }} />
          </RoundContainer>
        ) : null}
      </Space>
    </GreyContainer>
  );
};

export default CardsBlock;

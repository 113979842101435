import { useEffect, useState } from 'react';
import { getLinearGradientString } from 'common/lib/utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { COLORS } from 'river/lib/constants/styles';
import { setCurrentAccount } from 'river/store/actions/user';
import {
  getAccountBalance,
  getAccountIBAN,
  getAccountTitle,
  getAccounts,
  getCards,
  getIsAddCardAvailable,
  getCurrentAccountObject,
  getIsSoftBlocked,
  getAccountIsSoftBlocked,
} from 'river/store/selectors';
import { CARD_STATUS } from 'river/lib/constants/values';
import DrawerAccountName from './drawers/AccountName/DrawerAccountName';
import DrawerAddCard from './drawers/AddCard/DrawerAddCard';
import { ROUTES } from 'common/components/AppRouter/AppRouter';
import AccountLayout from 'common/components/layout/AccountLayout/AccountLayout';
import transferApi from '../../api/transfer';
import { FILE_FORMAT, PLANS } from '../../../common/lib/constants/constants';
import moment from 'moment';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import { getUniversalCard } from 'river/lib/utils/card';
import { getUserLocale } from '../../../common/store/selectors/app';
import { showSoftBlockModal } from 'river/store/actions/cards';

const Account = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [drawer, setDrawer] = useState<Record<string, boolean>>({
    accountName: false,
    addCard: false,
    transactions: false,
  });
  const { showError, showSuccess } = useMessage();
  const accountColor = getLinearGradientString(COLORS.MAIN.GRADIENT);
  const accounts = useSelector(getAccounts);
  const accountTitle = useSelector(getAccountTitle);
  const accountIBAN = useSelector(getAccountIBAN);
  const accountBalance = useSelector(getAccountBalance);
  const accountSoftBlocked = useSelector(getAccountIsSoftBlocked);
  const isSoftBlock = useSelector(getIsSoftBlocked);
  const cards = useSelector(getCards);
  const language = useSelector(getUserLocale);
  const accountCards = cards.map((card) => ({
    id: card.id,
    cardNumber: card.numberMasked,
    status: card.status,
  }));

  const accountStatus = useSelector(getCurrentAccountObject).status;

  const universalCards = cards.map((card) => getUniversalCard(card));

  const isAddCardAvailable =
    useSelector(getIsAddCardAvailable) &&
    accountCards.filter((card) => card.status !== CARD_STATUS.CLOSED).length === 0;

  const onEditName = () => {
    setDrawer((prev) => ({ ...prev, accountName: true }));
  };

  const onAddCard = () => {
    if (isSoftBlock || accountSoftBlocked) {
      dispatch(showSoftBlockModal());
      return;
    }
    setDrawer((prev) => ({ ...prev, addCard: true }));
  };

  const onOpenTransactions = () => {
    setDrawer((prev) => ({ ...prev, transactions: true }));
  };

  const onCloseTransactions = () => {
    setDrawer((prev) => ({ ...prev, transactions: false }));
  };

  const onSend = () => {
    if (isSoftBlock || accountSoftBlocked) {
      dispatch(showSoftBlockModal());
      return;
    }
    navigate(ROUTES.RIVER_SEND.path);
  };
  const onTopup = () => {
    if (isSoftBlock || accountSoftBlocked) {
      dispatch(showSoftBlockModal());
      return;
    }
    navigate(ROUTES.RIVER_TOP_UP.path);
  };

  const onCard = (cardId: number | string) => {
    navigate(`/pro/${id}/card/${String(cardId)}`);
  };

  useEffect(() => {
    if (!id || !accounts[id]) {
      navigate(-1);
      return;
    }

    dispatch(setCurrentAccount(id));
  }, [id, accounts, navigate, dispatch]);

  const onStatementDownload = async ({
    dateRange,
    format = FILE_FORMAT.PDF,
  }: {
    dateRange: Array<string>;
    format: 'pdf' | 'csv';
  }) => {
    try {
      const response = await transferApi.statement.download(Number(id), {
        start: moment(dateRange[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
        end: moment(dateRange[1], 'YYYY-MM-DD').format('DD-MM-YYYY'),
        format,
        language,
      });
      if (response.status === 200 && response.data) {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: `application/${format.toLowerCase()}` }),
        );
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.setAttribute('download', `statement.${format.toLowerCase()}`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink); // Удаление ссылки после использования
        window.URL.revokeObjectURL(url); // Освобождение ресурсов
      } else {
        console.error('Error: ', response.status);
      }
    } catch (e) {
      //
      console.log({ e });
    }
  };

  return (
    <>
      <AccountLayout
        color={accountColor}
        title={accountTitle}
        cards={universalCards}
        iban={accountIBAN}
        status={accountStatus}
        balance={accountBalance}
        // monthSpent={0}
        canAddCard={isAddCardAvailable}
        planName={PLANS.RIVER}
        onSend={onSend}
        onTopup={onTopup}
        onCard={onCard}
        onAddCard={onAddCard}
        onOpenTransactions={onOpenTransactions}
        onCloseTransactions={onCloseTransactions}
        openTransactions={drawer.transactions}
        onEditTitle={onEditName}
        onStatementDownload={onStatementDownload}
        showError={showError}
        showSuccess={showSuccess}
        accountSoftBlocked={accountSoftBlocked}
      />
      <DrawerAccountName
        isOpen={drawer.accountName}
        onClose={() => {
          setDrawer((prev) => ({ ...prev, accountName: false }));
        }}
        showError={showError}
        showSuccess={showSuccess}
      />
      <DrawerAddCard
        isOpen={drawer.addCard}
        onClose={() => {
          setDrawer((prev) => ({ ...prev, addCard: false }));
        }}
      />
    </>
  );
};

export default Account;

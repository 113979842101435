import React from 'react';
import { Flex, Modal, Result } from 'antd';
import { BlackButton } from 'common/components/UI';
import { useIntl } from 'react-intl';

type FreezeModalProps = {
  isOpen: boolean;
  onClose: () => unknown;
  onFreeze: () => unknown;
};

const FreezeModal: React.FC<FreezeModalProps> = ({ isOpen, onFreeze, onClose }) => {
  const { formatMessage: t } = useIntl();
  return (
    <Modal title="&nbsp;" open={isOpen} footer={false} onCancel={onClose}>
      <Result
        status="warning"
        title={t({ id: 'river.views.card.freezing.alert.title' })}
        subTitle={t({ id: 'river.views.card.freezing.alert.desc' })}
        extra={
          <Flex gap={8} vertical>
            <BlackButton id='btn__freeze-submit' type="primary" key="freeze" block onClick={onFreeze}>
              {t({ id: 'river.views.card.freezing.button.freeze' })}
            </BlackButton>
            <BlackButton id='btn__freeze-cancel' key="cancel" onClick={onClose} block>
              {t({ id: 'button.simple.cancel' })}
            </BlackButton>
          </Flex>
        }
      />
    </Modal>
  );
};

export default FreezeModal;

import { AccountsState, State } from './types';
import { RiverActions } from '../actions/types';
import { Card } from 'river/types';

const initialState: State = {
  accounts: {},
  meta: {},
  currentCard: null,
  currentAccount: null,
  softBlockModal: false,
  isSoftBlocked: false
};

//TODO: разбить на мелкие редюсеры
const riverReducer = (state = initialState, action: RiverActions) => {
  const payload = action?.payload;
  switch (action.type) {
    // case 'River/SET': {
    //   let nextState = { ...state, ...payload };

    //   // to replace only specific meta keys
    //   if (payload.meta) {
    //     nextState = {
    //       ...nextState,
    //       meta: { ...state.meta, ...payload.meta },
    //     };
    //   }

    //   // Handle accounts if passed
    //   if (payload.accounts) {
    //     const mappedAccounts = payload.accounts.reduce((acc, current) => {
    //       acc[current.id] = { ...current, cards: current.cards ?? [] };
    //       return acc;
    //     }, {});

    //     // Check if the currentAccount needs to be set
    //     const firstAccountId = payload.accounts[0]?.id;
    //     if (state.currentAccount === null && firstAccountId) {
    //       nextState = {
    //         ...nextState,
    //         accounts: mappedAccounts,
    //         currentAccount: firstAccountId,
    //       };
    //     } else {
    //       nextState = {
    //         ...nextState,
    //         accounts: mappedAccounts,
    //       };
    //     }
    //   }

    //   return nextState;
    // }

    case 'River/SET_CARDS': {
      const { accountId, cards } = action.payload;
      const updatedAccount = { ...state.accounts[accountId], cards };

      return {
        ...state,
        accounts: { ...state.accounts, [accountId]: updatedAccount },
      };
    }

    case 'River/SET_CURRENT_ACCOUNT':
      return {
        ...state,
        currentAccount: payload,
      };

    case 'River/SET_CURRENT_CARD':
      return {
        ...state,
        currentCard: payload,
      };

    case 'River/SET_ACCOUNTS': {
      const mappedAccounts = action.payload.reduce<AccountsState>((acc, current) => {
        acc[current.id] = { ...current, cards: current.cards ?? [] };
        return acc;
      }, {});

      return {
        ...state,
        accounts: mappedAccounts,
        currentAccount: payload[0].id,
      };
    }

    case 'River/REFRESH_ACCOUNTS': {
      const mappedAccounts = action.payload.reduce<AccountsState>((acc, current) => {
        acc[current.id] = { ...current, cards: current.cards ?? [] };
        return acc;
      }, {});

      return {
        ...state,
        accounts: mappedAccounts,
      };
    }

    case 'River/ADD_ACCOUNTS': {
      const newAccountsObj = action.payload.reduce<AccountsState>((acc, current) => {
        acc[current.id] = { ...current, cards: current.cards ?? [] };
        return acc;
      }, {});

      return {
        ...state,
        accounts: { ...state.accounts, ...newAccountsObj },
      };
    }

    case 'River/SET_META':
      return {
        ...state,
        meta: payload,
      };

    case 'River/SET_ACCOUNT_TITLE': {
      const { id, title } = action.payload;
      const updatedAccount = { ...state.accounts[id], title };

      return {
        ...state,
        accounts: { ...state.accounts, [id]: updatedAccount },
      };
    }

    case 'River/UPDATE_CARD': {
      const { accountId, card: updatedCard } = payload;
      const updatedAccounts: AccountsState = {
        ...state.accounts,
        [accountId]: {
          ...state.accounts[accountId],
          cards: state.accounts[accountId]?.cards?.map((card) => {
            if (card.id && card.id === updatedCard.id) {
              return updatedCard;
            }
            return card;
          }),
        },
      };

      return {
        ...state,
        accounts: updatedAccounts,
      };
    }

    case 'River/ADD_CARD': {
      const { accountId, card: updatedCard } = payload;
      const updatedAccounts: AccountsState = {
        ...state.accounts,
        [accountId]: {
          ...state.accounts[accountId],
          cards: state.accounts[accountId].cards
            ? [...state.accounts[accountId].cards!, updatedCard]
            : [updatedCard],
        },
      };

      return {
        ...state,
        accounts: updatedAccounts,
      };
    }

    case 'River/REMOVE_CARD': {
      const { accountId, cardId } = payload;

      const updatedCards: Card[] = []; 

      state.accounts[accountId]?.cards?.forEach((card)=>{
        if(String(card.id) !== String(cardId)) {
          updatedCards.push(card)
        }
      })


      const updatedAccounts: AccountsState = {
        ...state.accounts,
        [accountId]: {
          ...state.accounts[accountId],
          cards: updatedCards
        },
      };

      return {
        ...state,
        accounts: updatedAccounts,
      };
    }

    case 'River/ENROLL_CARD': {
      const { accountId, cardId } = payload;

      const updatedAccounts: AccountsState = {
        ...state.accounts,
        [accountId]: {
          ...state.accounts[accountId],
          cards: state.accounts[accountId]?.cards?.map((card) => {
            if (card.id && card.id === cardId) {
              return { ...card, isEnrolled: 1 };
            }
            return card;
          }),
        },
      };

      return {
        ...state,
        accounts: updatedAccounts,
      };
    }

    case 'River/UNENROLL_CARD': {
      const { accountId, cardId } = payload;

      const updatedAccounts: AccountsState = {
        ...state.accounts,
        [accountId]: {
          ...state.accounts[accountId],
          cards: state.accounts[accountId]?.cards?.map((card) => {
            if (card.id && card.id === cardId) {
              return { ...card, isEnrolled: 0 };
            }
            return card;
          }),
        },
      };

      return {
        ...state,
        accounts: updatedAccounts,
      };
    }

    case 'River/UPDATE_ACCOUNT': {
      const { accountId, updatedData } = payload;
      const updatedAccount = { ...state.accounts[accountId], ...updatedData };

      return {
        ...state,
        accounts: { ...state.accounts, [accountId]: updatedAccount },
      };
    }

    // case 'River/ADD_CARD': {
    //   const { accountId, card } = payload;
    //   const updatedAccount: AccountsState = {
    //     ...state.accounts[accountId],
    //     cards: [...state.accounts[accountId].cards, card],
    //   };

    //   return {
    //     ...state,
    //     accounts: { ...state.accounts, [accountId]: updatedAccount },
    //   };
    // }

    // case 'River/DELETE_CARD': {
    //   const { accountId, cardId } = payload;
    //   const updatedAccount: AccountsState = {
    //     ...state.accounts[accountId],
    //     cards: state.accounts[accountId].cards.filter((card) => card.id !== cardId),
    //   };

    //   return {
    //     ...state,
    //     accounts: { ...state.accounts, [accountId]: updatedAccount },
    //   };
    // }
    case 'River/SHOW_SOFT_BLOCK_MODAL': {
      return {
        ...state,
        softBlockModal: true,
      };
    }
    case 'River/HIDE_SOFT_BLOCK_MODAL': {
      return {
        ...state,
        softBlockModal: false,
      };
    }
    case 'River/IS_SOFT_BLOCK': {
      const {isSoftBlocked} = payload
      return {
        ...state,
        isSoftBlocked,
      };
    }
    case 'River/REPLACE_CARD': {
      const { accountId, cardId, updatedCard } = payload;
      const updatedAccounts = {
        ...state.accounts,
        [accountId]: {
          ...state.accounts[accountId],
          cards: state.accounts[accountId]?.cards?.map((card) => {
            if (card.id === cardId) {
              return updatedCard;
            }
            return card;
          }) || [],
        },
      };

      return {
        ...state,
        accounts: updatedAccounts,
      };
    }

    case 'River/SET_APPLICATIONS': {
      const applications = action.payload;

      const updatedAccounts = { ...state.accounts };

      applications.forEach((application) => {
        const accountKey = Object.keys(updatedAccounts).find((key) => {
          const account = updatedAccounts[key];
          return String(account.accountId) === String(application.accountId);
        });

        if (accountKey) {
          updatedAccounts[accountKey].cards = [
            ...(updatedAccounts[accountKey].cards || []),
            application,
          ];
        }
      });

      return {
        ...state,
        accounts: updatedAccounts,
      };
    }

    case 'User/LOG_OUT': {
      return initialState;
    }
    default:
      return state;
  }
};

export default riverReducer;

import React, { useState } from 'react';
import { Button, Flex, Form, Input, Progress, Select, Space, Typography } from 'antd';
import classes from '../../SignUp.module.scss';
import { getSignUpScheme } from '../../model/scheme';
import { Country } from 'common/types/app';
import { CreateAccountForm } from 'common/pages/Auth/SignUp/model/types';
import { useIntl } from 'react-intl';
import parsePhoneNumberFromString from 'libphonenumber-js';

interface PhoneFormProps {
  countries: Country[];
  formValues: Partial<CreateAccountForm>;
}
const PhoneForm: React.FC<PhoneFormProps> = ({ countries, formValues }) => {
  const [form] = Form.useForm();
  const { formatMessage: t } = useIntl();

  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string[] }>({
    phoneCountry: [],
    phoneNumber: [],
  });

  const onFieldsChange = () => {
    setValidationErrors((prevState) => ({
      ...prevState,
      phoneCountry: form.getFieldError('phoneCountry'),
      phoneNumber: form.getFieldError('phoneNumber'),
    }));
  };

  const phoneNumberValidator = async (_: any, value: string) => {
    const countryId = form.getFieldValue('phoneCountry');
    const country = countries.find((c) => c.id === countryId);
    if (!country) {
      return Promise.reject(
        new Error(t({ id: 'schemas.validation.phone.country.required' })),
      );
    }
    const phoneNumber = parsePhoneNumberFromString(`+${country.phoneCode}${value}`);

    if (!phoneNumber || !phoneNumber.isValid()) {
      return Promise.reject(new Error(t({ id: 'schemas.validation.phone.invalid' })));
    }
    return Promise.resolve();
  };

  const { phoneCountry, phoneNumber } = getSignUpScheme(t),
    formRules = {
      phoneCountry,
      phoneNumber: [...phoneNumber, { validator: phoneNumberValidator }],
    };

  const filterOption = (input: string, option?: { label: string; value: string }) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form
      initialValues={formValues}
      onFieldsChange={onFieldsChange}
      form={form}
      validateTrigger="onChange"
      name="step2">
      <Typography.Title className={classes.title}>
        {t({ id: 'screens.auth.signup.private.title' })}
      </Typography.Title>
      <Typography.Text className={classes.subTitle}>
        {t({ id: 'screens.createAccount.subTitle' })}
      </Typography.Text>
      <Progress percent={40} rootClassName={classes.progressBar} showInfo={false} />
      <Flex gap={8}>
        <Form.Item
          help={<div id="error__phone-country">{validationErrors.phoneCountry[0]}</div>}
          rules={formRules.phoneCountry}
          name="phoneCountry">
          <Select
            showSearch
            filterOption={filterOption}
            optionLabelProp="label"
            placement="bottomLeft"
            style={{ minWidth: 200, height: 55 }}
            placeholder="+000">
            {countries.map((country, idx) => (
              <Select.Option
                id={`phone-country${idx}`} 
                label={`+${country.phoneCode}`}
                value={country.id}
                key={country.id}>
                <div style={{ display: 'flex' }} id={`phone-country${idx}`}>
                  <div
                    className={`fi fi-${country.iso2.toLowerCase()}`}
                    style={{ marginRight: 6, flex: 'none' }}
                  />
                  <div
                    id={`phone-country${idx}-text`}>{`+${country.phoneCode}\t${country.name}`}</div>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          dependencies={['phoneCountry']}
          help={<div id="error__phone-number">{validationErrors.phoneNumber[0]}</div>}
          style={{ flexGrow: 1 }}
          rules={formRules.phoneNumber}
          name="phoneNumber">
          <Input placeholder="000-00-00" />
        </Form.Item>
      </Flex>
      <Button
        id="link__buy-number"
        className={classes.link}
        block
        type="link"
        href="https://lmt.lmt.lv/en/karte-esim"
        target="_blank">
        {t({ id: 'auth.buyEuropeanNumber' })}
      </Button>
      <Button id="btn__next" onClick={form.submit} type="primary" block>
        {t({ id: 'buttons.next' })}
      </Button>
    </Form>
  );
};

export default PhoneForm;

import { Card, Flex, Typography } from 'antd';
import classes from './SelectCard.module.scss';
import { DownOutlined } from '@ant-design/icons';
import React from 'react';
import { Account } from '../../types';
import { Card as CardComponent } from '../../../common/components/UI';
import { getLinearGradientString } from '../../../common/lib/utils/helpers';
import { COLORS } from '../../lib/constants/styles';

const { Text } = Typography;

type SelectCardProps = {
  currentOption: Account;
  className?: string;
  id?: string;
}

const Index: React.FC<SelectCardProps> = ({ currentOption, className, id }) => (
  <Card id={id} className={`${classes['card']}${className ? ' ' + classes[className] : ''}`}>
    <Flex justify="space-between" align="center">
      <Flex vertical gap={4}>
        <Text className={classes['card__subtitle']}>
          Weststein Pro | {currentOption?.iban}
        </Text>
        <Text className={classes['card__amount']}>
          {currentOption?.available ? currentOption?.available.toFixed(2) : '0.00'} €
        </Text>
      </Flex>
      <Flex gap={16} align="center">
        {currentOption?.cards && currentOption?.cards.length !== 0 &&
          <CardComponent
            size="s"
            shortNumber={currentOption?.cards[0].numberMasked.slice(-4)}
            bgColor={getLinearGradientString(COLORS.MAIN.GRADIENT)}
          />
        }
        <DownOutlined style={{
          color: '#00000099',
          fontSize: 16,
        }} />
      </Flex>
    </Flex>
  </Card>
);

export default Index;
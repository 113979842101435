import { Flex, Typography } from 'antd';
import classes from './Option.module.scss';
import React from 'react';
import { Account } from '../../types';
import { getLinearGradientString } from '../../../common/lib/utils/helpers';
import { COLORS } from '../../lib/constants/styles';
import { Card as CardComponent } from '../../../common/components/UI';

const { Text } = Typography;

type OptionProps = {
  onChange: (event: any, option: Account) => unknown;
  option: Account;
  id?: string;
}

const Option: React.FC<OptionProps> = ({ onChange, option, id }) => (
  <Flex id={id} justify="space-between" align="center" className={classes['option']}
        onClick={(event) => onChange(event, option)}>
    <Flex vertical gap={4}>
      <Text  style={{ fontSize: 14 }}>
        Weststein Pro | {option.iban}
      </Text>
      <Text style={{ fontSize: 18 }}>
        {option?.available ? option?.available.toFixed(2) : '0.00'} €
      </Text>
    </Flex>
    {option?.cards && option?.cards.length !== 0 &&
      <CardComponent
        size="s"
        shortNumber={option?.cards[0].numberMasked.slice(-4)}
        bgColor={getLinearGradientString(COLORS.MAIN.GRADIENT)}
      />
    }
  </Flex>
);

export default Option;
import { Card as AntdCard, Flex, Typography } from 'antd';
import classes from './SelectCard.module.scss';
import { DownOutlined } from '@ant-design/icons';
import React from 'react';
import { Card } from '../../types';
import { getLinearGradientString } from '../../../common/lib/utils/helpers';
import { COLORS } from '../../lib/constants/styles';
import { Card as CardComponent } from '../../../common/components/UI';

const { Text } = Typography;

type SelectCardProps = {
  currentOption: Card;
  className?: string;
  id?: string;
}

const Index: React.FC<SelectCardProps> = ({ currentOption, className, id }) => (
  <AntdCard id={id} className={`${classes['card']}${className ? ' ' + classes[className] : ''}`}>
    <Flex justify="space-between" align="center">
      <Flex vertical gap={4}>
        <Text className={classes['card__subtitle']}>
          Weststein Pro | {currentOption?.numberMasked}
        </Text>
      </Flex>
      <Flex gap={16} align="center">
        <CardComponent
          size="s"
          shortNumber={currentOption?.numberMasked.slice(-4) || ''}
          bgColor={getLinearGradientString(COLORS.MAIN.GRADIENT)}
        />
        <DownOutlined style={{
          color: '#00000099',
          fontSize: 16,
        }} />
      </Flex>
    </Flex>
  </AntdCard>
);

export default Index;
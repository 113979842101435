import React, { useCallback, useEffect } from 'react';
import { PhoneOutlined } from '@ant-design/icons';
import { Flex, Form, FormInstance, Input, Typography } from 'antd';
import { useIntl } from 'react-intl';
import classes from '../../Settings.module.css';
import PhoneInput, { PhoneInputState } from 'common/components/UI/PhoneInput/PhoneInput';
import { Country } from 'common/types/app';
import { BlackButton } from 'common/components/UI';
import { PhoneChangeState } from "../Phone";
import { general } from "../../../../../lib/schemas/rules";

const { Text } = Typography;
const { TextArea } = Input;

type PhoneRequestStepProps = {
  form: FormInstance;
  countries: Country[];
  phoneForm: PhoneChangeState;
  setPhoneForm: (phoneState: (PhoneChangeState | ((prev: PhoneChangeState) => PhoneChangeState))) => unknown;
  onNextStep: React.MouseEventHandler<HTMLElement>;
};

const PhoneRequestStep: React.FC<PhoneRequestStepProps> = ({
                                                             form,
                                                             countries,
                                                             phoneForm,
                                                             setPhoneForm,
                                                             onNextStep,
                                                           }) => {
  const { formatMessage: t } = useIntl();

  useEffect(() => {
    if (!form.getFieldValue('reason')) {
      setPhoneForm(prev => ({
        ...prev,
        haveErrors: true
      }));
    } else {
      setPhoneForm(prev => ({
        ...prev,
        haveErrors: false
      }));
    }
  }, [form, phoneForm.reason, phoneForm.haveErrors]);

  const setPhone = useCallback((values: PhoneInputState) => {
    setPhoneForm((prevState) => ({
      ...prevState,
      ...values
    }))
  }, [setPhoneForm])

  const setReason = (reason: string) => {
    setPhoneForm((prevState) => ({
      ...prevState,
      reason
    }))
  }

  return (
    <div>
      <Flex
        className={classes['slide-wrapper']}
        justify="center"
        align="center"
        vertical
        gap={16}
        style={{padding: 24}}
        >
        <PhoneOutlined
          style={{
            fontSize: 36,
            padding: 18,
            boxSizing: 'content-box',
            background: 'rgba(0, 0, 0, 0.04)',
            borderRadius: 100,
          }}
          rotate={90}
        />
        <Form
          form={form}
          // onFieldsChange={onFieldsChange}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            alignItems: 'center'
          }}
        >
          <Text className={classes.hint}>{t({ id: 'profile.settings.phone.new' })}:</Text>
          <PhoneInput countries={countries} onChange={setPhone} />
            <Flex vertical gap={16} style={{ width: '100%' }} align='center'>
              <Text className={classes.hint}>{t({id: 'profile.settings.phone.reason'})}:</Text>
              <Form.Item style={{ width: '100%' }} rules={[general(t)['required-field']]} name='reason'>
                <TextArea
                  autoSize={{
                    minRows: 3,
                    maxRows: 5,
                  }}
                  value={phoneForm.reason}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder={t({id: 'inputs.reason'})}
                />
              </Form.Item>
            </Flex>
          <BlackButton id='btn__phone-confirm' type="primary" disabled={phoneForm.haveErrors} onClick={onNextStep}>
            {t({ id: 'screens.setting.setting.phone.buttons.confirm' })}
          </BlackButton>
        </Form>
      </Flex>
    </div>
  );
};

export default PhoneRequestStep;

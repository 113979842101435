import React, { useEffect, useState } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { List, Switch, Typography } from 'antd';
import cardApi from 'river/api/card';
import { CardRuleTitle, CardRuleValue, CardRules } from 'river/types';
import { ONLY_PLASTIC_RULES } from 'river/lib/constants/values';

import blockClasses from './ManageCard.module.css';
import { useIntl } from 'react-intl';
import { RoundContainer } from "../../../../../common/components/UI";

const RULES_TRANSLATE: Record<CardRuleTitle, string> = {
  atm_disabled: 'river.views.card.rules.atm',
  contactless_disabled: 'river.views.card.rules.contactless',
  magnet_stripe_disabled: 'river.views.card.rules.magnet',
  ecommerce_disabled: 'river.views.card.rules.online',
};

type ManageCardProps = {
  currentCardId: string | number;
  currentAccountId: string | number;
  isVirtual: boolean;
  onGetPIN: () => unknown;
  showSuccess: (text: string, className?: string) => unknown;
  showError: (text: string, className?: string) => unknown;
};

const ManageCard: React.FC<ManageCardProps> = ({
  currentAccountId,
  currentCardId,
  isVirtual,
  showError,
  showSuccess,
  onGetPIN,
}) => {
  const [cardRules, setCardRules] = useState<CardRules>({
    atm_disabled: '0',
    contactless_disabled: '0',
    ecommerce_disabled: '0',
    magnet_stripe_disabled: '0',
  });
  const { formatMessage: t } = useIntl();

  const getRuleBoolean = (value: '0' | '1') => {
    return value === '1';
  };


  const getCardRules = () => {
    cardApi
      .getCardRules(+currentAccountId, currentCardId)
      .then(({ data: { response } }) => {
        setCardRules(response);
      });
  };

  const formattedRules = Object.entries(cardRules) as [
    title: CardRuleTitle,
    value: CardRuleValue,
  ][];

  const setCardRule = (name: CardRuleTitle, value: boolean) => {
    setCardRules((prev) => ({ ...prev, [name]: value ? '1' : '0' }));
    cardApi
      .setCardRule(+currentAccountId, currentCardId, {
        name,
        value: value ? 1 : 0,
      })
      .then(() => {
        showSuccess(t({id: 'common.message.success'}), `toast__card-rule-success`);
      })
      .catch(() => {
        showError(t({id: 'common.message.error'}), 'toast__card-rule-error');
        setCardRules((prev) => ({ ...prev, [name]: value ? '0' : '1' }));
      })
      .finally(() => {});
  };

  const onChangeRuleSwitcher = (checked: boolean, name: CardRuleTitle) => {
    setCardRule(name, checked);
  };

  useEffect(() => {
    getCardRules();
  }, []);


  return (
    <List>
       {!isVirtual && <List.Item
          className={blockClasses['list-item']}
          actions={[
            <RoundContainer
              size={32}
              key="change-pin-action"
              id='btn__change-pin'
              bgColor='transparent'
              pointer
              onClick={onGetPIN}
            >
              <RightOutlined
                style={{ fontSize: 16, color: 'rgba(0,0,0,0.6)' }}
              />
            </RoundContainer>,
          ]}>
          <List.Item.Meta title={t({id: 'card.manage.getPIN'})} />
        </List.Item>}
        {formattedRules.length &&
          formattedRules.map(([title, value]) =>
            isVirtual && ONLY_PLASTIC_RULES.includes(title) ? null : (
              <List.Item key={title} className={blockClasses['list-item']}>
                <List.Item.Meta title={t({ id: RULES_TRANSLATE[title] })} />
                <Switch
                  id={title + '-switcher'}
                  onChange={(value) => {
                    onChangeRuleSwitcher(value, title);
                  }}
                  value={getRuleBoolean(value)}
                />
              </List.Item>
            ),
          )}
      </List>
  );
};

export default ManageCard;

import React from 'react';
import { Flex, Space, Typography } from 'antd';
import {
  ArrowRightOutlined,
  EditFilled,
  PlusOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { getGreyColor } from 'common/lib/utils/helpers';
import {
  BackButton,
  ActionButton,
  GridContainer,
  RoundContainer,
} from 'common/components/UI';
import { PLANS } from 'common/lib/constants/constants';
import Balance from './components/Balance/Balance';
import CardsBlock from './blocks/Cards/Cards';
import OtherBlock from './blocks/Other/Other';
import RequisitesBlock from './blocks/Requisites/Requisites';
import classes from './AccountLayout.module.css';
import Transactions from 'river/pages/Account/drawers/Transactions/Transactions';
import { useIntl } from 'react-intl';
import { UniversalCard } from 'common/types/cards';
import { ACCOUNT_STATUS } from '../../../../river/lib/constants/values';

type AccountLayoutProps = {
  color: string;
  title: string;
  balance: number;
  monthSpent?: number;
  cards: UniversalCard[];
  iban: string;
  status?: string | null;
  canAddCard?: boolean;
  onEditTitle?: () => unknown;
  onAddCard?: () => unknown;
  onOpenTransactions?: () => unknown;
  onCloseTransactions?: () => unknown;
  openTransactions?: boolean;
  onCard?: (cardId: number | string) => unknown;
  onSend: () => unknown;
  onTopup: () => unknown;
  showError?: (text: string) => unknown;
  showSuccess?: (text: string) => unknown;
  onStatementDownload?: ({
    dateRange,
    format,
    id,
  }: {
    dateRange: Array<string>;
    format: 'pdf' | 'csv';
    id?: number | string;
  }) => Promise<any> | void;
  planName?: keyof typeof PLANS;
  accountSoftBlocked?: boolean
};

const AccountLayout: React.FC<AccountLayoutProps> = ({
  title,
  color,
  balance = 0,
  monthSpent = 0,
  status,
  iban,
  cards,
  canAddCard = false,
  planName,
  onCard,
  onEditTitle,
  onAddCard,
  onOpenTransactions,
  onCloseTransactions,
  openTransactions,
  onSend,
  onTopup,
  showError,
  showSuccess,
  onStatementDownload,
  accountSoftBlocked
}) => {
  const { formatMessage: t, locale } = useIntl();
  const isRiver = planName === PLANS.RIVER;

  return (
    <>
      <GridContainer
        rowProps={{
          style: {
            background: color,
            paddingBottom: 32,
          },
        }}>
        <BackButton />
        <Space className={classes.fullWidth} direction="vertical" size={24}>
          <Space className={classes.fullWidth} direction="vertical" size={4}>
            {status === ACCOUNT_STATUS.CLOSED && (
              <Flex gap={8} align="center" style={{ marginBottom: 20 }}>
                <StopOutlined style={{ fontSize: 16 }} />
                <Typography.Text style={{ fontWeight: 600, fontSize: 18 }}>
                  {t({ id: 'river.views.accountStatusPage.closed.title' })}
                </Typography.Text>
              </Flex>
            )}
            <Space size={8}>
              <Typography.Text className={classes.title}>{title}</Typography.Text>
              {onEditTitle && (
                <RoundContainer
                  id='#btn__account-name-change'
                  size={24}
                  bgColor={getGreyColor()}
                  pointer
                  onClick={onEditTitle}>
                  <EditFilled style={{ fontSize: 12 }} />
                </RoundContainer>
              )}
            </Space>
            <Balance balance={balance} isRiver={isRiver}/>
          </Space>
          <Flex gap={8}>
            <ActionButton
              text={t({ id: 'buttons.topup' })}
              icon={<PlusOutlined style={{ fontSize: 24 }} />}
              onClick={onTopup}
              id='btn__load'
            />
            <ActionButton
              text={t({ id: 'buttons.send' })}
              icon={<ArrowRightOutlined style={{ fontSize: 24 }} />}
              onClick={onSend}
              id='btn__send'
            />
          </Flex>
        </Space>
      </GridContainer>
      <GridContainer
        rowProps={{
          style: {
            marginTop: 32,
          },
        }}>
        <Space className={classes.fullWidth} direction="vertical" size={20}>
          {isRiver && (
            <Transactions
              onOpen={onOpenTransactions}
              onClose={onCloseTransactions}
              isOpen={openTransactions}
            />
          )}
          
          {(cards.length || canAddCard) && (
            <CardsBlock
              color={color}
              cards={cards}
              onCard={onCard}
              isAddCardAvailable={canAddCard && status !== ACCOUNT_STATUS.CLOSED} 
              onAddCard={onAddCard}
              accountSoftBlocked={accountSoftBlocked}
            />
          )}
          {isRiver && (
            <RequisitesBlock
              showError={showError}
              showSuccess={showSuccess}
              iban={iban}
            />
          )}
          <OtherBlock river={isRiver} onStatementDownload={onStatementDownload} />
        </Space>
      </GridContainer>
    </>
  );
};

export default AccountLayout;

import React, { useState } from 'react';
import {
  Button,
  Dropdown,
  Flex,
  Form,
  Input,
  InputNumber,
  Progress,
  Select,
  Space,
  Typography,
} from 'antd';
import classes from '../../SignUp.module.scss';
import { getSignUpScheme } from '../../model/scheme';
import { Country, IntlFormatMessageFunc } from 'common/types/app';
import { CreateAccountForm } from 'common/pages/Auth/SignUp/model/types';
import { useIntl } from 'react-intl';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';

interface AddressFormProps {
  countries: Country[];
  formValues: Partial<CreateAccountForm>;
}
const AddressForm: React.FC<AddressFormProps> = ({ countries, formValues }) => {
  const [form] = Form.useForm();
  const { formatMessage: t } = useIntl();

  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string[] }>({
    country: [],
    city: [],
    postalCode: [],
    address: [],
  });

  const onFieldsChange = () => {
    setValidationErrors((prevState) => ({
      ...prevState,
      country: form.getFieldError('country'),
      city: form.getFieldError('city'),
      postalCode: form.getFieldError('postalCode'),
      address: form.getFieldError('address'),
    }));
  };

  const postalCodeValidator = (_: any, value: any) => {
    const countryId = form.getFieldValue('country');
    if (!countryId) return Promise.resolve();

    const countryObj = countries.filter((c) => c.id === countryId)[0];
    const countryIso2 = countryObj.iso2;

    if (postcodeValidatorExistsForCountry(countryIso2)) {
      const res = postcodeValidator(value, countryIso2);
      if (!res) {
        return Promise.reject(t({ id: 'schemas.postalCode.invalid' }));
      }
    }

    return Promise.resolve();
  };

  const rules = getSignUpScheme(t);
  const { postalCode } = getSignUpScheme(t),
    formRules = {
      ...rules,
      postalCode: [...postalCode, { validator: postalCodeValidator }],
    };

  const filterOption = (input: string, option?: { label: string; value: string }) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form
      initialValues={formValues}
      onFieldsChange={onFieldsChange}
      form={form}
      validateTrigger="onChange"
      name="step1">
      <Typography.Title className={classes.title}>
        {t({ id: 'screens.auth.signup.private.title' })}
      </Typography.Title>
      <Typography.Text className={classes.subTitle}>
        {t({ id: 'screens.createAccount.subTitle' })}
      </Typography.Text>
      <Progress percent={20} rootClassName={classes.progressBar} showInfo={false} />
      <Form.Item
        help={<div id="error__country">{validationErrors.country[0]}</div>}
        rules={formRules.country}
        name="country">
        <Select
          showSearch
          filterOption={filterOption}
          placement="bottomLeft"
          placeholder={t({ id: 'inputs.country' })}>
          {countries
            .filter((country) => country.enroll)
            .map((country, idx) => (
              <Select.Option
                id={`country${idx}`}
                label={country.name}
                value={country.id}
                key={country.id}>
                <div id={`country${idx}`} style={{ display: 'flex' }}>
                  <div
                    className={`fi fi-${country.iso2.toLowerCase()}`}
                    style={{ marginRight: 6 }}
                  />
                  <div>{country.name}</div>
                </div>
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        help={<div id="error__city">{validationErrors.city[0]}</div>}
        rules={formRules.city}
        name="city">
        <Input placeholder={t({ id: 'inputs.city' })} />
      </Form.Item>
      <Form.Item
        help={<div id="error__address">{validationErrors.address[0]}</div>}
        rules={formRules.address}
        name="address">
        <Input placeholder={t({ id: 'inputs.address' })} />
      </Form.Item>
      <Form.Item
        help={<div id="error__postal-code">{validationErrors.postalCode[0]}</div>}
        rules={formRules.postalCode}
        dependencies={['country']}
        name="postalCode">
        <Input placeholder={t({ id: 'inputs.postalCode' })} />
      </Form.Item>

      <Button id="btn__next" onClick={form.submit} type="primary" block>
        {t({ id: 'buttons.next' })}
      </Button>
    </Form>
  );
};

export default AddressForm;

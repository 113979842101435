import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { RootState } from 'common/store';
import { Locale } from './types';

type Messages = {
  [id: string]: string;
};

type LocaleDataLoader = () => Promise<{ default: Messages }>;

type Locales = {
  [key in Locale]: LocaleDataLoader;
};

const locales: Locales = {
  BG: () => import('./translates/bg.json'),
  CZ: () => import('./translates/cz.json'),
  DE: () => import('./translates/de.json'),
  EE: () => import('./translates/ee.json'),
  EN: () => import('./translates/en.json'),
  ES: () => import('./translates/es.json'),
  FR: () => import('./translates/fr.json'),
  IT: () => import('./translates/it.json'),
  LT: () => import('./translates/lt.json'),
  LV: () => import('./translates/lv.json'),
  NL: () => import('./translates/nl.json'),
  PL: () => import('./translates/pl.json'),
  RU: () => import('./translates/ru.json'),
};

const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const language = useSelector((state: RootState) => state.language);
  const [messages, setMessages] = useState<Messages>({});

  const loadLocaleData = async (locale: Locale) => {
    try {
      const defaultMessagesRes = await locales['EN']();
      const defaultMessages = defaultMessagesRes.default;

      const localeMessagesRes =
        locale !== 'EN' ? await locales[locale]() : { default: {} };
      const localeMessages = localeMessagesRes.default;

      setMessages({
        ...defaultMessages,
        ...localeMessages,
      });
    } catch (error) {
      console.error(`Unable to load messages for locale ${locale}:`, error);
    }
  };

  useEffect(() => {
    void loadLocaleData(language.locale as Locale);
  }, [language.locale]);

  return (
    <IntlProvider locale={language.locale} messages={messages} defaultLocale="en">
      {children}
    </IntlProvider>
  );
};

export default LanguageProvider;

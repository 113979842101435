import React, { useEffect, useMemo, useState } from 'react';
import classes from './SignUp.module.scss';
import { Flex, Form, message } from 'antd';
import { AddressForm, PasswordForm, PersonalDataForm, PhoneForm } from './components/CreateUser';
import { FormFinishInfo } from 'rc-field-form/lib/FormContext';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCountries, hideLoader, showLoader } from 'common/store/actions/app';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from 'common/store';
import { AnyAction } from 'redux';
import { CreateAccountForm } from './model/types';
import { createAccount } from 'common/pages/Auth/SignUp/actions';
import { ReactComponent as ArrowLeftIcon } from 'common/assets/svg/arrow-left.svg';
import { ROUTES } from 'common/components/AppRouter/AppRouter';
import { useIntl } from 'react-intl';
import { getLocalizedCountries } from '../../../lib/utils/countries';
import { getEnrollCountries, getPhoneCountries } from '../../../store/selectors/app';
import Index from "../../../modals/ForbiddenModal";
import appApi from "../../../api/app";
import { useMessage } from "../../../lib/hooks/useMessage/useMessage";

declare global {
  interface Window {
    grecaptcha: {
      ready: (callback: () => void) => void;
      execute: (siteKey: string | undefined, options: { action: string }) => Promise<string>;
    };
  }
}

const SignUp: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [formData, setFormData] = useState<Partial<CreateAccountForm>>({});
  const [showForbiddenModal, setShowForbiddenModal] = useState<boolean>(false);

  const { formatMessage: t } = useIntl();
  const { showError } = useMessage();
  const countries = getLocalizedCountries(useSelector(getEnrollCountries), t);
  const phoneCountries = useSelector(getPhoneCountries);

  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();

  useEffect(() => {
    checkIp();
  }, [])

  const checkIp = async () => {
    dispatch(showLoader({ type: 'backdrop' }));
    try {
      const { data: { response } } = await appApi.checkCountryIp();

      if (response) {
        setShowForbiddenModal(true);
        return;
      }
    } catch (err) {
      showError(t({ id: 'common.message.error' }));
      navigate(ROUTES.SIGN_IN.path);
    } finally {
      dispatch(hideLoader());
    }
  }

  const toNextStep = () => {
    setCurrentStep(prev => (prev < Object.keys(steps).length - 1 ? prev + 1 : prev));
  };
  const toPrevStep = () => {
    setCurrentStep(prev => (prev > 0 ? prev - 1 : prev));
  };

  const steps: Record<number, JSX.Element> = useMemo(() => ({
    0: <PersonalDataForm formValues={formData} countries={countries} />,
    1: <AddressForm formValues={formData} countries={countries} />,
    2: <PhoneForm formValues={formData} countries={phoneCountries} />,
    3: <PasswordForm formValues={formData} />,
  }), [countries, formData, phoneCountries]);

  const onEachFormFinish = (formName: string, formInfo: FormFinishInfo) => {
    const updatedFormData = { ...formData, ...formInfo.values };
    setFormData(updatedFormData);
    if (formName === 'step3') {
      window.grecaptcha.ready(function () {
        window.grecaptcha.execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(function (token: string | undefined) {
            dispatch(createAccount({ ...updatedFormData, 'g-recaptcha-response': token }))
              .then(() => {
                navigate(ROUTES.VERIFY_EMAIL.path);
              })
              .catch(apiErrors => {
                // check if ip is restricted
                if (!apiErrors[0].id && apiErrors[0].defaultMessage) {
                  setShowForbiddenModal(true);
                  return;
                }

                if (apiErrors) {
                  // Sorting errors
                  apiErrors.sort((a: any, b: any) => {
                    return a.field === b.field && b.id.length - a.id.length;
                  });
                  if (apiErrors.find((error: any) => error.id === 'errors.auth.registration.captcha')) {
                    message.error('reCaptcha key error');
                  }
                  // Перенаправление на соответствующий экран в случае ошибок
                  const phoneError = apiErrors.find((error: any) => error.field === 'phone');
                  const emailError = apiErrors.find((error: any) => error.field === 'email');
                  const dateError = apiErrors.find((error: any) => error.field === 'date' || error.field === 'dateOfBirth');
                  const emailMessageError = apiErrors.find((error: any) => error.field.toLowerCase().includes('email'))
                  // Displaying errors in notification
                  if(emailMessageError){
                    apiErrors.forEach((e: any) => {
                      message.error({
                        content: t({ id: e.id }, { name: t({ id: e.field }) }),
                        className: `toast__${e.field}_${e.id.replaceAll('.', '-')}`
                      });
                    });
                  } else{
                  apiErrors.forEach((e: any) => {
                    message.error({
                      content: t({ id: e.id }, { name: t({ id: `inputs.${e.field}` }) }),
                      className: `toast__${e.field}_${e.id.replaceAll('.', '-')}`
                    });
                  });
                   } 
                  if (emailError || dateError || emailMessageError) setCurrentStep(0);
                  else if (phoneError) setCurrentStep(2);
                }
              });
          });
      });
    } else toNextStep();
  };
  useEffect(() => {
    void dispatch(getCountries());
  }, []);

  return (
    <div className={classes.wrapper}>
      <Index
        open={showForbiddenModal}
        onClose={() => {
          setShowForbiddenModal(false);
          navigate(ROUTES.SIGN_IN.path);
        }}
      />
      <div className={classes.container}>
        <Flex align="flex-start" justify="center" className="w100">
          {currentStep !== 0 && (
            <div onClick={toPrevStep} className={classes.arrowLeftIcon}>
              <ArrowLeftIcon style={{ height: 22, width: 22 }} />
            </div>
          )}
          <div className={classes.formContainer}>
            <Form.Provider onFormFinish={onEachFormFinish}>
              {steps[currentStep]}
            </Form.Provider>
          </div>
        </Flex>
      </div>
    </div>
  );
};
export default SignUp;
